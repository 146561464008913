'use client'

import Markdown from 'markdown-to-jsx'
import { useState } from 'react'
import Icon from '../icons/Icon'
import { bm } from '../../utilities/bliss'
import Dropdown from '@/src/components/button/Dropdown'

const FAQItem = ({ faqItem, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  const handleToggle = () => setIsOpen((prev) => !prev)

  return (
    <div className="mb-4 rounded-xl bg-white text-sm shadow-[0px_7px_30px_rgba(211,155,123,0.3)]">
      <div className="flex cursor-pointer justify-between px-6 py-8 font-bold" onClick={handleToggle}>
        <div className="flex items-center">
          {faqItem.iconName && <Icon icon={faqItem.iconName} size="sm" color="primary" className="mx-6" />}
          <span>{faqItem.question}</span>
        </div>
        <Icon icon={isOpen ? 'circle-minus' : 'circle-plus'} color="primary" size="md" />
      </div>
      <Dropdown isOpen={isOpen}>
        <div className={bm('faq-content', '', 'border-0 border-t-[2px] border-solid border-light px-10 py-6')}>
          {faqItem.answer && <Markdown>{faqItem.answer}</Markdown>}
        </div>
      </Dropdown>
    </div>
  )
}

const FAQ = ({ faqs }) => {
  return (
    <div className="w-full py-6">
      {faqs.entry.map((faqItem, key) => {
        return <FAQItem faqItem={faqItem} key={`faq-${key}`} defaultOpen={key === 0} />
      })}
    </div>
  )
}

export default FAQ
