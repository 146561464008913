'use client'

import { getTranslate } from '@/src/utilities/i18n'
import { useSearchParams } from 'next/navigation'
import Link from 'next/link'
import { LOCALE } from '@/src/constants/locale.constants'

type Props = {
  className?: string
  type: 'google' | 'apple'
}

export const APP_STORE_ID = 'id6451498329'

const AppButton: React.FC<Props> = ({ className, type }) => {
  const { t } = getTranslate()

  const searchParams = useSearchParams()

  const utmSource = searchParams.get('utm_source') ?? 'web'
  const utmMedium = searchParams.get('utm_medium') ?? 'link'
  const utmCampaign = searchParams.get('utm_campaign') ?? 'web_app_url'

  const APP_STORE_LINK = `https://apps.apple.com/${process.env.NEXT_PUBLIC_COUNTRY_IDENTIFIER}/app/hedepy/${APP_STORE_ID}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`

  const GOOGLE_PLAY_LINK = `https://play.google.com/store/apps/details?id=com.hedepy.hedepy&hl=${LOCALE}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`

  return (
    <div>
      {type === 'apple' ? (
        <Link href={APP_STORE_LINK} target="_blank" rel="noopener noreferrer">
          <img src="/images/svg/misc/AppStore.svg" alt={t('components.pageBlocks.appButtons')} className={className} />
        </Link>
      ) : (
        <Link href={GOOGLE_PLAY_LINK} target="_blank" rel="noopener noreferrer">
          <img
            src="/images/svg/misc/GooglePlay.svg"
            alt={t('components.pageBlocks.appButtons')}
            className={className}
          />
        </Link>
      )}
    </div>
  )
}

export default AppButton
