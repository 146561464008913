'use client'

import Icon from '@/src/components/icons/Icon'
import { getTranslate } from '@/src/utilities/i18n'
import { ShowMore, ShowMoreRef, ShowMoreToggleLinesFn } from '@re-dev/react-truncate'
import { useRef } from 'react'
import dayjs from 'dayjs'
import Markdown from 'markdown-to-jsx'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import { CustomerReviewType } from './types'
import { cn, isNewBrandingActive } from '@/src/rebrand/utilities'

const PersonInfo = ({ stars, name }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <img alt="gift-shared" src="/images/gift-icon.png" width={39} height={39} className="mr-2 rounded-full" />
        <div>
          <div className="text-base font-bold text-secondary">{name}</div>

          {[...Array(stars)].map((_star, key) => {
            return <Icon key={`star-${key}`} icon="star" size="xs" className="text-[#F1BF42]" />
          })}
        </div>
      </div>
      <Icon icon="google" size="sm" className="mr-4" />
    </div>
  )
}

type MoreButtonProps = {
  onClick: ShowMoreToggleLinesFn
  isExpanded?: boolean
}

const MoreButton: React.FC<MoreButtonProps> = ({ onClick, isExpanded }) => {
  const { t } = getTranslate()
  return (
    <>
      {!isExpanded && '...'}
      <br />
      <RadixButton variant="inline" onClick={onClick} className="mt-3 text-lg font-bold normal-case underline">
        {isExpanded ? t('component.reviews.readLess') : t('component.reviews.readAll')}
        <Icon icon="select-arrow" size="xs" className={`${isExpanded ? 'rotate-180' : ''} mx-3`} />
      </RadixButton>
    </>
  )
}

type Props = {
  review: CustomerReviewType
  className?: string
}

const Review: React.FC<Props> = ({ review, className }) => {
  const { t } = getTranslate()
  const ref = useRef<ShowMoreRef>(null)

  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    e.stopPropagation()
    ref.current?.toggleLines(e)
  }

  return (
    <div
      className={cn(
        `inline-block flex break-inside-avoid flex-col justify-between rounded-2xl ${
          isNewBrandingActive ? 'bg-white' : 'bg-body-bg'
        } p-6 tablet:w-full`,
        className,
      )}
    >
      <div>
        {review.stars ? (
          <PersonInfo stars={review.stars} name={review.name} />
        ) : (
          <div
            className={`flex h-8 w-8 items-center justify-center rounded-full ${
              isNewBrandingActive ? 'bg-yellow-500' : 'bg-white'
            } `}
          >
            <Icon icon="quote" color="secondary" size="md" />
          </div>
        )}

        <div className={`text-md py-3 leading-7 text-petroleum`}>
          <ShowMore
            ref={ref}
            lines={8}
            more={<MoreButton onClick={toggleLines} />}
            less={<MoreButton onClick={toggleLines} isExpanded />}
          >
            {review.text}
          </ShowMore>
        </div>
      </div>
      <div className="">
        <hr className="border-1 m-0 w-full border-solid bg-bordeaux-background" />

        <div className="py-3 text-sm text-text-color">{review.note}</div>
        <div className="text-xs text-text-color-light">
          <Markdown>
            {t(`component.reviews.dateLabel`, { date: `• ${dayjs(review.date).format('DD. MM. YYYY')}` })}
          </Markdown>
        </div>
      </div>
    </div>
  )
}
export default Review
