import { RegisterOptions, useFormContext } from 'react-hook-form'
import React, { useState } from 'react'
import { useIsFieldInvalid } from './hooks/useIsFieldInvalid'
import ErrorMessage from './ErrorMessage'
import { getTranslate } from '@/src/utilities/i18n'
import Icon from '../icons/Icon'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import { cn, isNewBrandingActive } from '@/src/rebrand/utilities'

type InputFieldProps = {
  readonly label?: React.ReactNode
  readonly name: string
  readonly type?: string
  readonly required?: boolean
  readonly className?: string
  readonly placeholder?: string
  readonly prefixText?: string
  readonly disabled?: boolean
  readonly registerOptions?: RegisterOptions
  readonly content?: React.ReactNode
  readonly optional?: boolean
  readonly showEye?: boolean
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  required,
  className,
  registerOptions,
  placeholder,
  prefixText,
  content,
  type = 'text',
  optional,
  showEye = false,
  ...props
}) => {
  const { t } = getTranslate()
  const isInvalid = useIsFieldInvalid(name)
  const { register } = useFormContext()

  const [showText, setShowText] = useState(false)

  return (
    <>
      <div className={`form-group ${className}`}>
        {label && (
          <label htmlFor={name} className={cn('relative text-sm')}>
            {label}
            {required && <sup className="static text-base">*</sup>}
            {optional && (
              <span className="text-lowercase font-normal text-text-color-light"> ({t(`registration.optional`)})</span>
            )}
          </label>
        )}
        {content}
        <div className="relative">
          <input
            id={name}
            {...props}
            type={showText ? '' : type}
            {...register(name, registerOptions)}
            placeholder={placeholder}
            className={cn(
              `form-control`,
              isInvalid ? 'is-invalid' : '',
              isNewBrandingActive ? 'placeholder-sand-700' : '',
            )}
            autoComplete="off"
          />
          {showEye && (
            <RadixButton
              onClick={() => setShowText(!showText)}
              variant="inline"
              className="absolute inset-y-0 right-4 m-0 p-0 focus:shadow-none"
            >
              <Icon className="" color="primary" size="sm" icon={showText ? 'eye' : 'eye-disabled'} />
            </RadixButton>
          )}
        </div>
        <ErrorMessage name={name} />
      </div>
    </>
  )
}

export default InputField
