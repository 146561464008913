import React from 'react'
import { getTranslate } from 'src/utilities/i18n'
import IconTextRebrand from '../icons/IconTextRebrand'

type Props = { verified: number }

const VerifiedTraining: React.FC<Props> = ({ verified }) => {
  const { t } = getTranslate()

  return <IconTextRebrand iconName="frequent-rebrand" text={t(`therapistProfile.verification.${verified}`)} />
}

export default VerifiedTraining
