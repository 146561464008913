export enum ComponentNamesRest {
  anchor = 'section.anchor-section',
  faq = 'section.faq-section',
  richContent = 'component.rich-content',
  academy = 'Academy',
  articlesLinks = 'section.articles-links-section',
  ytVideo = 'component.youtube-video',
  videoContent = 'component.video-content',
  note = 'component.note',
  buttonLink = 'component.button-link',
  citation = 'section.citation-section',
}
