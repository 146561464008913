import { getTranslate } from 'src/utilities/i18n'
import React from 'react'
import Icon from '../icons/Icon'
import Image from 'next/image'

type Props = {
  readonly topic: any
}

const TopicTeaserSidebar: React.FC<Props> = ({ topic }) => {
  const { t } = getTranslate()

  return (
    <div className="flex w-full flex-col">
      <div className={`relative mb-4 flex h-[220px] overflow-hidden rounded-3xl bg-petroleum tablet:h-[9.6rem]`}>
        <div className="relative w-1/2">
          <div className="absolute -bottom-6 right-0">
            <Image
              alt={topic.content.cover?.title}
              src={topic.content.cover?.data?.attributes?.url}
              fill
              sizes="137px"
            />
          </div>
        </div>
      </div>
      <div className="mb-6 flex flex-col text-base font-bold text-text-color">
        <div className="">{topic.title}</div>
        <div className="text-xs font-normal">{topic.perex}</div>

        <div className="self-end text-xs">
          {t('component.premiumContent.lessonFooter.showFull')}
          <Icon icon="arrow" size="sm" className="ml-7" color="primary" />
        </div>
      </div>
    </div>
  )
}

export default TopicTeaserSidebar
