import {
  DiagnosisFieldsPayload,
  ExtendedDiagnosisFields,
  HIGH_BASE_VALUE_MARKER,
  LOW_BASE_VALUE_MARKER,
} from '@/src/components/diagnosis/guide/types'
import { getStringFromHash } from '@/src/utilities/codingPublicHash'
import { TherapistPriceType } from '@/src/types/TherapistStateType'
import { STYLE_LIMITS } from '@/src/components/recommendedTherapist/constants'

export const normalizeDiagnosisData = (data: ExtendedDiagnosisFields): DiagnosisFieldsPayload => {
  return {
    ...data,
    lead: data.lead * 5,
    emotions: data.emotions * 3,
    past: data.past * 4,
    personality: data.personality * 5,
    specialization: data.specialization.map((item) => parseInt(item, 10)),
    services: [data.service],
    therapyActivities: data.therapyActivities?.length
      ? data.therapyActivities.map((item) => parseInt(item, 10))
      : undefined,
    // these are temporary overrides since we have several processing functions
    // the proper fix would be to use the same data processing in both sending diagnosis and diagnosis preview
    ...(typeof data.therapistAgeGroup === 'string' ? { therapistAgeGroup: parseInt(data.therapistAgeGroup, 10) } : {}),
    ...(typeof data.sex === 'string' ? { sex: parseInt(data.sex, 10) } : {}),
    ...(typeof data.maxPrice === 'string' ? { maxPrice: parseInt(data.maxPrice, 10) } : {}),
  }
}

export const normalizeDiagnosisDataV2 = (data: ExtendedDiagnosisFields) => {
  return {
    ...normalizeDiagnosisData(data),
    therapistAgeGroup: parseInt(data.therapistAgeGroup, 10),
  }
}

export const parseDiagnosisParams = (paramsString: string) => {
  try {
    const paramsJson = getStringFromHash(paramsString as string)
    const params = paramsJson ? JSON.parse(paramsJson) : null
    return paramsJson ? params : null
  } catch {
    return null
  }
}

export const getPricePoints = (therapists, selectedServiceId: number) => {
  const serviceMatchinTherapist = therapists.filter(
    (therapist) => therapist.services?.length && therapist.services.includes(selectedServiceId),
  )
  const pricesMatchingService = serviceMatchinTherapist
    .map((therapist) => therapist.prices?.find((price: TherapistPriceType) => price.serviceId === selectedServiceId))
    .filter((price) => price !== undefined)

  const priceOccurrences: { [key: number]: number } = {}
  pricesMatchingService.forEach((item) => {
    const price = item.price
    priceOccurrences[price] = (priceOccurrences[price] || 0) + 1
  })

  // Exclude prices that appear only once
  return pricesMatchingService

    .filter((item) => priceOccurrences[item.price] > 1)
    .map((item) => item.price)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a - b)
}

export const isNewDiagnosisExtendedData = (data) => {
  return Number.isInteger(data?.therapistAgeGroup)
}

export const hasDiagnosisFilledOut = (diagnosis) => diagnosis && Object.keys(diagnosis).length > 0

export const styleMatchText = (styleMatch: number) => {
  if (styleMatch >= STYLE_LIMITS.great) {
    return 'great'
  } else if (styleMatch >= STYLE_LIMITS.good) {
    return 'good'
  } else if (styleMatch >= STYLE_LIMITS.ok) {
    return 'ok'
  } else {
    return 'poor'
  }
}

export const parseDefaultValues = (params: string) => {
  const paramsJson = getStringFromHash(params)
  return paramsJson ? JSON.parse(paramsJson) : {}
}

export const genderList = (t) => [
  {
    label: t('diagnosisTest.sex.0'),
    value: '0',
    icon: 'gender-man',
  },
  {
    label: t('diagnosisTest.sex.1'),
    value: '1',
    icon: 'gender-woman',
  },
  {
    label: t('diagnosisTest.sex.2'),
    value: '2',
    icon: 'gender-both',
  },
]

export const ageList = (t) => [
  {
    label: t('diagnosisTest.age.0'),
    value: 0,
  },
  {
    label: t('diagnosisTest.age.1'),
    value: 1,
  },
  {
    label: t('diagnosisTest.age.2'),
    value: 2,
  },
  {
    label: t('diagnosisTest.age.3'),
    value: 3,
  },
]

export const computeValuesForBaseScale = (form) => {
  const COEFFICIENT_OF_ONE_SELECTED = 0.8
  const BASE_SCORE = 3
  const fields = ['emotions', 'past', 'lead', 'personality']

  fields.forEach((fieldName) => {
    const fieldHigh = `${fieldName}-${HIGH_BASE_VALUE_MARKER}` as string
    const fieldLow = `${fieldName}-${LOW_BASE_VALUE_MARKER}` as string
    const signedScore = form.getValues(fieldLow) ? -BASE_SCORE : BASE_SCORE

    if (form.getValues(fieldHigh) !== form.getValues(fieldLow)) {
      form.setValue(fieldName, Math.round(COEFFICIENT_OF_ONE_SELECTED * signedScore * 100) / 100)
    } else {
      form.setValue(fieldName, 0)
    }
    form.unregister(fieldHigh)
    form.unregister(fieldLow)
  })
}
