import React, { useState } from 'react'
import Icon from '../icons/Icon'
import Link from 'next/link'
import { UrlObject } from 'url'
import Image from 'next/image'
import { cn } from '@/src/rebrand/utilities'

type Props = {
  source?: string
  identifier: string
  openModal?: null | (() => void)
  href?: UrlObject | string
  className?: string
  width?: number
  height?: number
  prefetch?: boolean
}

const TherapistProfileImage: React.FC<Props> = ({
  source,
  identifier,
  openModal = null,
  href,
  className = '',
  width = 80,
  height = 80,
  prefetch,
}) => {
  const [imageUrl, setImageUrl] = useState(source)

  const fallbackUrl = '/images/svg/misc/therapist-placeholder.svg'

  const renderImage = () => (
    <Image
      src={imageUrl || fallbackUrl}
      alt={identifier}
      fill
      sizes={`${width}px`}
      onError={() => {
        setImageUrl(fallbackUrl)
      }}
      className="overflow-hidden"
      style={{
        maskImage: "url('/images/ellipsis-rebrand.svg')",
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        objectFit: 'cover',
        overflowClipMargin: 'unset',
      }}
    />
  )

  return (
    <div className={cn('relative', className)} style={{ height, width }}>
      {href ? <Link {...{ href, prefetch }}>{renderImage()}</Link> : renderImage()}
      {typeof openModal === 'function' && (
        <button
          className="absolute -bottom-[5px] -right-[5px] flex h-[32px] w-[32px] items-center justify-center rounded-lg border-none bg-yellow-500 transition-colors hover:bg-yellow-600"
          onClick={openModal}
          type="button"
        >
          <Icon icon="play" color="primary" size="xs" />
        </button>
      )}
    </div>
  )
}

export default TherapistProfileImage
