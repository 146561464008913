import React from 'react'
import { ButtonProps, RadixButton, RadixButtonLink } from '@/src/rebrand/buttonRadix'
import Icon from '@/src/components/icons/Icon'
import { cn } from '@/src/rebrand/utilities'
import { fontSerifVariable } from '@/src/rebrand/fonts'
import { getTranslate } from '@/src/utilities/i18n'

const FindYourMatchButton: React.FC<{ size: ButtonProps['size']; className?: string }> = ({ size, className = '' }) => {
  const { t } = getTranslate()
  return (
    <RadixButtonLink href="/diagnosis" size={size} className={className}>
      {t('components.pageBlocks.mainHeader.button')}
    </RadixButtonLink>
  )
}

const LoginButton: React.FC<{ size: ButtonProps['size'] }> = ({ size }) => {
  const { t } = getTranslate()

  return (
    <RadixButtonLink
      href="/login"
      variant="yellow"
      size={size}
      className="mr-2 h-[44px] text-[10px] big-phone:h-auto big-phone:text-[13px]"
    >
      {t('component.button.login')}
      <span className="hidden big-phone:inline-block">
        <Icon icon="arrow" color="primary" size="xs" className="ml-1" />
      </span>
    </RadixButtonLink>
  )
}

const MenuButton: React.FC<{ children: React.ReactNode; href?: string; className?: string }> = ({
  children,
  href,
  className,
}) => {
  const styleProps: ButtonProps = {
    variant: 'ghost',
    size: 'medium',
    className: cn(
      `text-secondary px-0 h-auto laptop:px-3 py-3 laptop:py-6 laptop:h-full normal-case text-base ${fontSerifVariable}`,
      className,
    ),
  }
  return href ? (
    <RadixButtonLink href={href} {...styleProps}>
      {children}
    </RadixButtonLink>
  ) : (
    <RadixButton asChild {...styleProps}>
      {children}
    </RadixButton>
  )
}

export { LoginButton, FindYourMatchButton, MenuButton }
