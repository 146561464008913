import { be, bm } from '../../utilities/bliss'
import Icon from '../icons/Icon'
import Markdown from 'markdown-to-jsx'
import { useFormContext, RegisterOptions, useWatch } from 'react-hook-form'
import React from 'react'
import { useIsFieldInvalid } from './hooks/useIsFieldInvalid'
import ErrorMessage from './ErrorMessage'
import { useFormError } from '@/src/components/formFields/hooks/useFormError'

type CheckboxFieldProps = {
  readonly label: string
  readonly value?: string | number
  readonly name: string
  readonly checked?: boolean
  readonly className?: string
  readonly type?: string
  readonly onChange?: (event: any) => void
  readonly registerOptions?: RegisterOptions
  readonly buttonStyle?: boolean
  readonly listCol?: string
  readonly text?: string
  readonly useTileStyles?: boolean
  readonly link?: string
  readonly prefixText?: string
  readonly disabled?: boolean
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  name,
  className,
  registerOptions,
  link,
  checked,
  onChange,
  type,
  disabled,
  ...props
}) => {
  const isInvalid = useIsFieldInvalid(name)
  const { register } = useFormContext()
  const value = useWatch({ name })
  const error = useFormError(name)
  const isChecked = onChange ? value?.length && value.some((item) => item === props.value) : value

  return (
    <div
      className={be(
        'checkbox',
        'item',
        [type, isChecked ? 'checked' : ''],
        `custom-control ${disabled ? 'opacity-50' : ''} ${className}`,
      )}
    >
      <div className={bm('custom-checkbox')}>
        <label className={`custom-control-label ${isChecked ? 'checked' : ''} ${error ? 'text-danger' : ''}`}>
          {onChange !== undefined && type === 'plain' && (
            <Icon color="brown" icon={isChecked ? 'checkboxFull' : 'checkbox'} />
          )}
          <input
            {...register(name, registerOptions)}
            {...props}
            type="checkbox"
            onClick={onChange}
            className={`custom-control-input${isInvalid ? 'is-invalid' : ''}`}
            disabled={disabled}
          />
          <Markdown
            options={{
              overrides: {
                a: {
                  props: {
                    target: '_blank',
                  },
                },
              },
            }}
          >
            {label}
          </Markdown>
        </label>
      </div>
      {!onChange && <ErrorMessage name={name} />}
    </div>
  )
}

export default CheckboxField
