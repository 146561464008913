import React from 'react'
import { ServiceType } from '@/src/types/GeneralStateType'
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '@/src/components/header/navigationRadix'
import Container from '@/src/rebrand/Container'
import HeaderSupportSection from '@/src/components/header/HeaderSupportSection'
import HeaderTherapistsContent from '@/src/components/header/HeaderTherapistsContent'
import HeaderOtherContent from '@/src/components/header/HeaderOtherContent'
import { MenuButton } from '@/src/components/header/MenuComponents'
import { CollapsibleContent, CollapsibleTrigger } from '@radix-ui/react-collapsible'
import { Collapsible } from '@/src/rebrand/collapsibleRadix'
import Icon from '../icons/Icon'
import { MenuType } from '@/src/utilities/apiUtils'
import { getHrefFromStrapiMenuItem } from '@/src/utilities/strapi'

type NavigationType = { title: string } & (
  | { href: string; content?: never }
  | { href?: never; content: React.ReactNode }
)

const navigation = (services: ServiceType[], menu: MenuType[], otherMenu: MenuType[]): NavigationType[] =>
  menu.map((item) => ({
    title: item.attributes.title,
    ...(item.attributes.special_function === 'other'
      ? { content: <HeaderOtherContent otherMenu={otherMenu} /> }
      : item.attributes.special_function === 'therapists'
        ? { content: <HeaderTherapistsContent services={services} /> }
        : { href: getHrefFromStrapiMenuItem(item) }),
  }))

const NavigationIterator: React.FC<{
  services: ServiceType[]
  isMobile?: boolean
  menu: MenuType[]
  otherMenu: MenuType[]
  displayPhone: boolean
}> = ({ services, menu, otherMenu, isMobile = false, displayPhone }) => (
  <>
    {navigation(services, menu, otherMenu).map(({ title, href, content }, index) => (
      <NavigationMenuItem key={index} className="w-full laptop:w-auto">
        {href ? (
          <NavigationMenuLink asChild className="whitespace-nowrap">
            <MenuButton href={href}>{title}</MenuButton>
          </NavigationMenuLink>
        ) : isMobile ? (
          <>
            <Collapsible>
              <MenuButton>
                <CollapsibleTrigger className="group border-none !bg-[transparent]">
                  {title}{' '}
                  <Icon
                    color="primary"
                    icon="selectArrow"
                    size="xxs"
                    svgType="symbolic"
                    className="relative top-[1px] mb-0.5 ml-1.5 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                  />
                </CollapsibleTrigger>
              </MenuButton>
              <CollapsibleContent className="CollapsibleContent">
                <Container className="hidden p-0 laptop:block">
                  {content} <HeaderSupportSection displayPhone={displayPhone} />
                </Container>
                <div className="block p-0 laptop:hidden">{content}</div>
              </CollapsibleContent>
            </Collapsible>
          </>
        ) : (
          <>
            <MenuButton>
              <NavigationMenuTrigger className="border-none !bg-[transparent]">{title}</NavigationMenuTrigger>
            </MenuButton>
            <NavigationMenuContent
              className="overflow-y-auto border-0 border-solid border-sand-500 py-3 laptop:border-b-2 laptop:py-5"
              // use for styling / debugging purposes to display menu permanently
              // onPointerMove={(event) => event.preventDefault()}
              // onPointerLeave={(event) => event.preventDefault()}
            >
              <Container className="hidden p-0 laptop:block">
                {content} <HeaderSupportSection displayPhone={displayPhone} />
              </Container>
              <div className="block p-0 laptop:hidden">{content}</div>
            </NavigationMenuContent>
          </>
        )}
      </NavigationMenuItem>
    ))}
  </>
)

export default NavigationIterator
