import { assocPath, pathOr } from 'ramda'

export const solveNewValue = (prevArray, value) => {
  let changed = false
  let newArray = prevArray.filter((item) => {
    if (item === value) {
      changed = true
      return false
    }
    return true
  })

  if (!changed) {
    newArray = [...prevArray, value].filter((item) => {
      if (typeof item === 'string') {
        return item.trim() !== ''
      }
      return !isNaN(item)
    })
  }

  return newArray
}

export const mergeValuesInArray = (state: Object, path: string, value: Object | string | boolean | number) => {
  const statePath = path.split('.')
  const prevArray = pathOr([], statePath, state)
  if (prevArray.length === 0) {
    return assocPath(statePath, [value], state)
  }
  const newArray = solveNewValue(prevArray, value)

  return assocPath(statePath, newArray, state)
}

export default { mergeValuesInArray }
